/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/
/* Theme for the ripple elements.*/
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f3f3f3;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b4b4b4;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: silver;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #dadada;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #a7a7a7;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f3f3f3;
}

.mat-checkbox-checkmark-path {
  stroke: #f3f3f3 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f3f3f3;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #dadada;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(44, 44, 44, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #105c9d;
}
.mat-icon.mat-accent {
  color: #105c9d;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #105c9d;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #105c9d;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #dadada;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #0097aa;
}

.mat-progress-bar-buffer {
  background-color: #0097aa;
}

.mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #105c9d;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(243, 243, 243, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary[disabled], .theme-alternate .mat-button.mat-accent[disabled], .theme-alternate .mat-button.mat-warn[disabled], .theme-alternate .mat-button[disabled][disabled], .theme-alternate .mat-icon-button.mat-primary[disabled], .theme-alternate .mat-icon-button.mat-accent[disabled], .theme-alternate .mat-icon-button.mat-warn[disabled], .theme-alternate .mat-icon-button[disabled][disabled], .theme-alternate .mat-stroked-button.mat-primary[disabled], .theme-alternate .mat-stroked-button.mat-accent[disabled], .theme-alternate .mat-stroked-button.mat-warn[disabled], .theme-alternate .mat-stroked-button[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-icon-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  background-color: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab[disabled]:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-accent .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #0097aa;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #0097aa;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

#ta-transport-status-wrapper {
  display: flex;
  align-items: center;
  /* Absolute Center Spinner */
  /* Transparent Overlay */
}
#ta-transport-status-wrapper .status-icon {
  font-size: 1.2em;
  line-height: 1.5em;
  height: auto;
}
#ta-transport-status-wrapper .status-label {
  font-weight: bold;
  margin-left: 0.25em;
}
#ta-transport-status-wrapper .PICKED_UP {
  color: #333333;
}
#ta-transport-status-wrapper .DELIVERED {
  color: #007e33;
}
#ta-transport-status-wrapper .MISSED {
  color: #cc0000;
}
#ta-transport-status-wrapper .MISSED_PICKUP,
#ta-transport-status-wrapper .MISSED_DELIVERY,
#ta-transport-status-wrapper .hasProblem {
  color: #d0911c;
}
#ta-transport-status-wrapper .loading-indicator {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#ta-transport-status-wrapper .loading-indicator:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.transport-status-log-dialog .mat-dialog-content {
  padding: 0;
  margin: 0;
}
.transport-status-log-dialog .mat-dialog-content table {
  width: 100%;
}
.transport-status-log-dialog .mat-close-btn {
  color: #fff !important;
  background: #0b4566 !important;
  margin-bottom: -150px;
}

#transport-order-wizard-wrapper {
  max-width: 740px;
  margin: auto;
}
#transport-order-wizard-wrapper h1 {
  text-align: center;
  margin: 0.5em 0;
}
#transport-order-wizard-wrapper .step-content {
  margin: 1em 0;
}
#transport-order-wizard-wrapper .step-content .transport-details-wrapper {
  display: flex;
}
#transport-order-wizard-wrapper .step-content .transport-details-wrapper ta-package-picker {
  flex: 1 1 35%;
}
#transport-order-wizard-wrapper .step-content .transport-details-wrapper .comments-wrapper {
  flex: 1 1 65%;
  margin-left: 1em;
}
#transport-order-wizard-wrapper .step-content .internal-message-wrapper {
  padding-top: 2em;
}
#transport-order-wizard-wrapper .actions {
  display: flex;
  justify-content: flex-end;
}
#transport-order-wizard-wrapper .actions button {
  font-size: 1.2em;
  height: 45px;
}
#transport-order-wizard-wrapper mat-calendar {
  max-width: 400px;
}
#transport-order-wizard-wrapper .abort-button-wrapper {
  margin-top: 2em;
  width: 100%;
  text-align: center;
}

.main-layout #transport-order-wizard-wrapper {
  margin-top: 143px;
}
.main-layout #transport-order-wizard-wrapper .actions button {
  height: auto;
  font-size: 14px;
}

@media screen and (max-width: 450px) {
  #transport-order-wizard-wrapper .actions button {
    width: 100%;
    font-size: 1.2em;
    height: 45px;
  }
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f3f3f3;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b4b4b4;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: silver;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #dadada;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #a7a7a7;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f3f3f3;
}

.mat-checkbox-checkmark-path {
  stroke: #f3f3f3 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f3f3f3;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #dadada;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(44, 44, 44, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #105c9d;
}
.mat-icon.mat-accent {
  color: #105c9d;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #105c9d;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #105c9d;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #dadada;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #0097aa;
}

.mat-progress-bar-buffer {
  background-color: #0097aa;
}

.mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #105c9d;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(243, 243, 243, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary[disabled], .theme-alternate .mat-button.mat-accent[disabled], .theme-alternate .mat-button.mat-warn[disabled], .theme-alternate .mat-button[disabled][disabled], .theme-alternate .mat-icon-button.mat-primary[disabled], .theme-alternate .mat-icon-button.mat-accent[disabled], .theme-alternate .mat-icon-button.mat-warn[disabled], .theme-alternate .mat-icon-button[disabled][disabled], .theme-alternate .mat-stroked-button.mat-primary[disabled], .theme-alternate .mat-stroked-button.mat-accent[disabled], .theme-alternate .mat-stroked-button.mat-warn[disabled], .theme-alternate .mat-stroked-button[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-icon-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  background-color: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab[disabled]:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-accent .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #0097aa;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #0097aa;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

.cdk-overlay-container .mat-autocomplete-panel mat-option {
  height: 60px;
}
.cdk-overlay-container .mat-autocomplete-panel mat-option .clinic-item-name {
  font-size: 1.2em;
  line-height: normal;
}
.cdk-overlay-container .mat-autocomplete-panel mat-option .clinic-item-address {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.54);
  line-height: normal;
  margin-top: 3px;
}

@media screen and (max-width: 480px) {
  #transport-app .mat-step-text-label {
    display: none;
  }
  #transport-app .mat-stepper-horizontal-line {
    margin: 0 12px;
  }
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f3f3f3;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b4b4b4;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: silver;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #dadada;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #a7a7a7;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f3f3f3;
}

.mat-checkbox-checkmark-path {
  stroke: #f3f3f3 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f3f3f3;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #dadada;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(44, 44, 44, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #105c9d;
}
.mat-icon.mat-accent {
  color: #105c9d;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #105c9d;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #105c9d;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #dadada;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #0097aa;
}

.mat-progress-bar-buffer {
  background-color: #0097aa;
}

.mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #105c9d;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(243, 243, 243, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary[disabled], .theme-alternate .mat-button.mat-accent[disabled], .theme-alternate .mat-button.mat-warn[disabled], .theme-alternate .mat-button[disabled][disabled], .theme-alternate .mat-icon-button.mat-primary[disabled], .theme-alternate .mat-icon-button.mat-accent[disabled], .theme-alternate .mat-icon-button.mat-warn[disabled], .theme-alternate .mat-icon-button[disabled][disabled], .theme-alternate .mat-stroked-button.mat-primary[disabled], .theme-alternate .mat-stroked-button.mat-accent[disabled], .theme-alternate .mat-stroked-button.mat-warn[disabled], .theme-alternate .mat-stroked-button[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-icon-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  background-color: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab[disabled]:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-accent .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #0097aa;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #0097aa;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

#transport-app .transport-dates-wrapper {
  display: flex;
  flex-wrap: wrap;
}
#transport-app .transport-dates-wrapper .select-date-wrapper {
  flex: 1 0 50%;
  padding-right: 0.5em;
}
#transport-app .transport-dates-wrapper .select-date-wrapper mat-form-field,
#transport-app .transport-dates-wrapper .select-date-wrapper input {
  cursor: pointer;
}
#transport-app .transport-dates-wrapper .deadline-wrapper {
  flex: 1 0 50%;
  padding-left: 0.5em;
}
#transport-app .transport-dates-wrapper .second-date-wrapper {
  flex: 1 0 100%;
  padding: 0.75em;
  border-radius: 4px;
  margin-top: 1em;
  background-color: #fffbe2e8;
}
#transport-app .transport-dates-wrapper .second-date-wrapper label {
  margin: 0;
}
#transport-app .transport-dates-wrapper .second-date-wrapper .second-date {
  display: flex;
}
#transport-app .transport-dates-wrapper .second-date-wrapper .second-date .warning-icon {
  text-align: center;
  margin-right: 0.5em;
  opacity: 0.5;
  font-size: 2em;
  line-height: 1.5em;
}
#transport-app .transport-dates-wrapper .delivery-date-bg {
  background-color: #e5f6fd !important;
}
#transport-app .transport-dates-wrapper.pickup .select-date-wrapper {
  flex: 1 1 100%;
  padding: 0;
}

.cdk-overlay-container .mat-calendar-body-cell-content {
  font-size: 1.2em;
}

@media screen and (max-width: 450px) {
  #transport-app .transport-dates-wrapper .select-date-wrapper,
#transport-app .transport-dates-wrapper .deadline-wrapper {
    flex: 1 1 100%;
    padding: 0;
    margin-bottom: 1em;
  }
}
#existing-transport-dialog-wrapper mat-dialog-content {
  margin-bottom: 2em;
}
#existing-transport-dialog-wrapper .close {
  float: right;
  top: -20px;
  right: -20px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f3f3f3;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b4b4b4;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: silver;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #dadada;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #a7a7a7;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f3f3f3;
}

.mat-checkbox-checkmark-path {
  stroke: #f3f3f3 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f3f3f3;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #dadada;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(44, 44, 44, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #105c9d;
}
.mat-icon.mat-accent {
  color: #105c9d;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #105c9d;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #105c9d;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #dadada;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #0097aa;
}

.mat-progress-bar-buffer {
  background-color: #0097aa;
}

.mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #105c9d;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(243, 243, 243, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary[disabled], .theme-alternate .mat-button.mat-accent[disabled], .theme-alternate .mat-button.mat-warn[disabled], .theme-alternate .mat-button[disabled][disabled], .theme-alternate .mat-icon-button.mat-primary[disabled], .theme-alternate .mat-icon-button.mat-accent[disabled], .theme-alternate .mat-icon-button.mat-warn[disabled], .theme-alternate .mat-icon-button[disabled][disabled], .theme-alternate .mat-stroked-button.mat-primary[disabled], .theme-alternate .mat-stroked-button.mat-accent[disabled], .theme-alternate .mat-stroked-button.mat-warn[disabled], .theme-alternate .mat-stroked-button[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-icon-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  background-color: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab[disabled]:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-accent .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #0097aa;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #0097aa;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

#scheduled-transports-wrapper {
  max-width: 1400px;
  margin: auto;
  padding: 1em;
}
#scheduled-transports-wrapper .transport-list {
  background-color: #fff;
}
#scheduled-transports-wrapper .transport-list .item {
  display: flex;
  border-bottom: 1px #f3f3f3 solid;
}
#scheduled-transports-wrapper .transport-list .item .cell {
  background-color: #fff;
  align-self: center;
  padding: 0.5em 1em;
  min-width: 155px;
}
#scheduled-transports-wrapper .transport-list .no-transport-day {
  padding: 1em;
  text-align: center;
  color: silver;
}
#scheduled-transports-wrapper .transport-list .header {
  display: flex;
}
#scheduled-transports-wrapper .transport-list .header .cell {
  padding: 0.5em 1em;
  font-weight: bold;
  color: #fff;
  min-width: 155px;
  background-color: #105c9d;
}
#scheduled-transports-wrapper .transport-list .header .cell mat-icon {
  color: rgba(0, 0, 0, 0.8);
  margin-right: 0.25em;
}
#scheduled-transports-wrapper .transport-list .header .clinic-cell {
  flex: 1 0 40%;
}
#scheduled-transports-wrapper .transport-list .header .pickup-cell,
#scheduled-transports-wrapper .transport-list .header .delivery-cell {
  text-align: center;
  flex: 1 0 30%;
  display: flex;
  justify-content: center;
}
#scheduled-transports-wrapper mat-spinner {
  margin: auto;
  margin-top: 100px;
  animation: fadeIn 0.6s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.main-layout #scheduled-transports-wrapper {
  margin-top: 143px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (max-width: 480px) {
  #scheduled-transports-wrapper .transport-list {
    overflow-x: scroll;
    width: 100%;
  }
}
transport-row {
  width: 100%;
  display: flex;
}
transport-row .cell {
  display: flex;
  align-self: center;
  padding: 0.5em 1em;
}
transport-row .cell .transport-info {
  flex: 1 0 auto;
  align-self: center;
}
transport-row .clinic-cell {
  flex: 1 0 40%;
}
transport-row .pickup-cell,
transport-row .delivery-cell {
  text-align: center;
  flex: 1 0 30%;
}
transport-row .pickup-cell .transport-info,
transport-row .delivery-cell .transport-info {
  position: relative;
}
transport-row .pickup-cell .transport-info ta-transport-status,
transport-row .delivery-cell .transport-info ta-transport-status {
  position: absolute;
  top: 0;
  left: 0;
}
transport-row .pickup-cell .transport-info .actual-packages,
transport-row .delivery-cell .transport-info .actual-packages {
  font-weight: bold;
}
transport-row .pickup-cell .no-transport,
transport-row .delivery-cell .no-transport {
  width: 100%;
  text-align: center;
  color: #e3e3e3;
}
transport-row .two-pickups {
  display: flex;
  flex-direction: column;
}
transport-row .two-pickups .ta-transport-status {
  position: static !important;
}

#scheduled-transports-filters-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
#scheduled-transports-filters-wrapper .date-picker-wrapper {
  flex: 0 0 auto;
}
#scheduled-transports-filters-wrapper .date-picker-wrapper mat-form-field {
  max-width: 200px;
  cursor: pointer;
}
#scheduled-transports-filters-wrapper .date-picker-wrapper mat-form-field input {
  cursor: pointer;
}
#scheduled-transports-filters-wrapper .filter-wrapper {
  flex: 1 0 auto;
  margin-left: 1em;
}
#scheduled-transports-filters-wrapper .filter-wrapper mat-form-field {
  max-width: 200px;
}
#scheduled-transports-filters-wrapper .print-button-wrapper {
  margin-right: 1em;
}
#scheduled-transports-filters-wrapper .new-order-button-wrapper button {
  margin-right: 1em;
}

@media screen and (max-width: 480px) {
  #scheduled-transports-filters-wrapper {
    margin-bottom: 1em;
  }
  #scheduled-transports-filters-wrapper .date-picker-wrapper,
#scheduled-transports-filters-wrapper .filter-wrapper,
#scheduled-transports-filters-wrapper .print-button-wrapper {
    display: none;
  }
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto;
  letter-spacing: 0em;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto;
  letter-spacing: 0.0094em;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 500 15px/24px Roboto;
  letter-spacing: 0.0067em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto;
  letter-spacing: 0.0333em;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto;
  letter-spacing: -0.0134em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto;
  letter-spacing: -0.0089em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto;
  letter-spacing: 0em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto;
  letter-spacing: 0.0074em;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
}

.mat-expansion-panel-header {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto;
  letter-spacing: 0.0179em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto;
  letter-spacing: 1.5px;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto;
  font-size: 12px;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slider-thumb-label-text {
  font-size: 12px;
  font-weight: 500;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto;
  letter-spacing: 0.0075em;
  margin: 0;
}

.mat-tooltip {
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto;
  letter-spacing: 0.0179em;
}

.mat-simple-snackbar {
  font-family: Roboto;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-lighter-color;
  --primary-darker-color: $primary-darker-color;
  --text-primary-color: #ffffff;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: #ffffff;
}

body {
  --accent-color: $accent-color;
  --accent-lighter-color: $accent-lighter-color;
  --accent-darker-color: $accent-darker-color;
  --text-accent-color: #ffffff;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: #ffffff;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #ffffff;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: #ffffff;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(44, 44, 44, 0.04);
}
.mat-option.mat-active {
  background: rgba(44, 44, 44, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #f3f3f3;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-app-background {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b4b4b4;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: red;
}
.mat-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-stroked-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.mat-button[disabled] .mat-button-focus-overlay, .mat-icon-button[disabled] .mat-button-focus-overlay, .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: red;
}
.mat-flat-button.mat-primary[disabled], .mat-flat-button.mat-accent[disabled], .mat-flat-button.mat-warn[disabled], .mat-flat-button[disabled][disabled], .mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled], .mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled], .mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled], .mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled], .mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  background-color: rgba(44, 44, 44, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab[disabled]:not([class*=mat-elevation-z]), .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: silver;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #dadada;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #a7a7a7;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #f3f3f3;
}

.mat-checkbox-checkmark-path {
  stroke: #f3f3f3 !important;
}

.mat-checkbox-mixedmark {
  background-color: #f3f3f3;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}

.mat-chip.mat-standard-chip {
  background-color: #dadada;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(44, 44, 44, 0.04);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.mat-datepicker-toggle-active.mat-warn {
  color: red;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(44, 44, 44, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}

.mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}

.mat-error {
  color: red;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #105c9d;
}
.mat-icon.mat-accent {
  color: #105c9d;
}
.mat-icon.mat-warn {
  color: red;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #105c9d;
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-accent .mat-input-element {
  caret-color: #105c9d;
}

.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: red;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #dadada;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(44, 44, 44, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(44, 44, 44, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(44, 44, 44, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-progress-bar-background {
  fill: #0097aa;
}

.mat-progress-bar-buffer {
  background-color: #0097aa;
}

.mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: red;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(44, 44, 44, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #f3f3f3;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(44, 44, 44, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-header, .mat-tab-group.mat-background-primary .mat-tab-links, .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-primary .mat-tab-label, .mat-tab-group.mat-background-primary .mat-tab-link, .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-header, .mat-tab-group.mat-background-accent .mat-tab-links, .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.mat-tab-group.mat-background-accent .mat-tab-label, .mat-tab-group.mat-background-accent .mat-tab-link, .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-header, .mat-tab-group.mat-background-warn .mat-tab-links, .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.mat-tab-group.mat-background-warn .mat-tab-label, .mat-tab-group.mat-background-warn .mat-tab-link, .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #105c9d;
}

.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-option {
  color: white;
}
.theme-alternate .mat-option:hover:not(.mat-option-disabled), .theme-alternate .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-option.mat-active {
  background: rgba(243, 243, 243, 0.04);
  color: white;
}
.theme-alternate .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #105c9d;
}
.theme-alternate .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: red;
}
.theme-alternate .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-pseudo-checkbox-checked,
.theme-alternate .mat-pseudo-checkbox-indeterminate,
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #105c9d;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: red;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.theme-alternate .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-autocomplete-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #393939;
}
.theme-alternate .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.theme-alternate .mat-badge-content {
  color: white;
  background: #105c9d;
}
.cdk-high-contrast-active .theme-alternate .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.theme-alternate .mat-badge-accent .mat-badge-content {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-badge-warn .mat-badge-content {
  color: white;
  background: red;
}
.theme-alternate .mat-badge {
  position: relative;
}
.theme-alternate .mat-badge-hidden .mat-badge-content {
  display: none;
}
.theme-alternate .mat-badge-disabled .mat-badge-content {
  background: #6b6b6b;
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.theme-alternate .ng-animate-disabled .mat-badge-content,
.theme-alternate .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.theme-alternate .mat-badge-content.mat-badge-active {
  transform: none;
}
.theme-alternate .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.theme-alternate .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .theme-alternate .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.theme-alternate .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.theme-alternate .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .theme-alternate .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.theme-alternate .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.theme-alternate .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .theme-alternate .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.theme-alternate .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-button, .theme-alternate .mat-icon-button, .theme-alternate .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.theme-alternate .mat-button.mat-primary, .theme-alternate .mat-icon-button.mat-primary, .theme-alternate .mat-stroked-button.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-accent, .theme-alternate .mat-icon-button.mat-accent, .theme-alternate .mat-stroked-button.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-button.mat-warn, .theme-alternate .mat-icon-button.mat-warn, .theme-alternate .mat-stroked-button.mat-warn {
  color: red;
}
.theme-alternate .mat-button.mat-primary[disabled], .theme-alternate .mat-button.mat-accent[disabled], .theme-alternate .mat-button.mat-warn[disabled], .theme-alternate .mat-button[disabled][disabled], .theme-alternate .mat-icon-button.mat-primary[disabled], .theme-alternate .mat-icon-button.mat-accent[disabled], .theme-alternate .mat-icon-button.mat-warn[disabled], .theme-alternate .mat-icon-button[disabled][disabled], .theme-alternate .mat-stroked-button.mat-primary[disabled], .theme-alternate .mat-stroked-button.mat-accent[disabled], .theme-alternate .mat-stroked-button.mat-warn[disabled], .theme-alternate .mat-stroked-button[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-primary .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-accent .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #105c9d;
}
.theme-alternate .mat-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-icon-button.mat-warn .mat-button-focus-overlay, .theme-alternate .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: red;
}
.theme-alternate .mat-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-icon-button[disabled] .mat-button-focus-overlay, .theme-alternate .mat-stroked-button[disabled] .mat-button-focus-overlay {
  background-color: transparent;
}
.theme-alternate .mat-button .mat-ripple-element, .theme-alternate .mat-icon-button .mat-ripple-element, .theme-alternate .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.theme-alternate .mat-button-focus-overlay {
  background: #ffffff;
}
.theme-alternate .mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-flat-button, .theme-alternate .mat-raised-button, .theme-alternate .mat-fab, .theme-alternate .mat-mini-fab {
  color: white;
  background-color: #393939;
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  color: white;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  color: white;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  color: white;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-flat-button.mat-primary, .theme-alternate .mat-raised-button.mat-primary, .theme-alternate .mat-fab.mat-primary, .theme-alternate .mat-mini-fab.mat-primary {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-accent, .theme-alternate .mat-raised-button.mat-accent, .theme-alternate .mat-fab.mat-accent, .theme-alternate .mat-mini-fab.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-flat-button.mat-warn, .theme-alternate .mat-raised-button.mat-warn, .theme-alternate .mat-fab.mat-warn, .theme-alternate .mat-mini-fab.mat-warn {
  background-color: red;
}
.theme-alternate .mat-flat-button.mat-primary[disabled], .theme-alternate .mat-flat-button.mat-accent[disabled], .theme-alternate .mat-flat-button.mat-warn[disabled], .theme-alternate .mat-flat-button[disabled][disabled], .theme-alternate .mat-raised-button.mat-primary[disabled], .theme-alternate .mat-raised-button.mat-accent[disabled], .theme-alternate .mat-raised-button.mat-warn[disabled], .theme-alternate .mat-raised-button[disabled][disabled], .theme-alternate .mat-fab.mat-primary[disabled], .theme-alternate .mat-fab.mat-accent[disabled], .theme-alternate .mat-fab.mat-warn[disabled], .theme-alternate .mat-fab[disabled][disabled], .theme-alternate .mat-mini-fab.mat-primary[disabled], .theme-alternate .mat-mini-fab.mat-accent[disabled], .theme-alternate .mat-mini-fab.mat-warn[disabled], .theme-alternate .mat-mini-fab[disabled][disabled] {
  background-color: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-flat-button.mat-primary .mat-ripple-element, .theme-alternate .mat-raised-button.mat-primary .mat-ripple-element, .theme-alternate .mat-fab.mat-primary .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-accent .mat-ripple-element, .theme-alternate .mat-raised-button.mat-accent .mat-ripple-element, .theme-alternate .mat-fab.mat-accent .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-flat-button.mat-warn .mat-ripple-element, .theme-alternate .mat-raised-button.mat-warn .mat-ripple-element, .theme-alternate .mat-fab.mat-warn .mat-ripple-element, .theme-alternate .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-stroked-button:not([class*=mat-elevation-z]), .theme-alternate .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-raised-button[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab:not([disabled]):active:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab:not([disabled]):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-fab[disabled]:not([class*=mat-elevation-z]), .theme-alternate .mat-mini-fab[disabled]:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone,
.theme-alternate .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.theme-alternate .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-appearance-standard {
  color: white;
  background: #393939;
}
.theme-alternate .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.theme-alternate .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-button-toggle-checked {
  background-color: #5f5f5f;
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.theme-alternate .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: #464646;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #393939;
}
.theme-alternate .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #797979;
}
.theme-alternate .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.theme-alternate .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-card {
  background: #393939;
  color: white;
}
.theme-alternate .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox-checkmark {
  fill: #2c2c2c;
}
.theme-alternate .mat-checkbox-checkmark-path {
  stroke: #2c2c2c !important;
}
.theme-alternate .mat-checkbox-mixedmark {
  background-color: #2c2c2c;
}
.theme-alternate .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #105c9d;
}
.theme-alternate .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .theme-alternate .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: red;
}
.theme-alternate .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .theme-alternate .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.theme-alternate .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.theme-alternate .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-checkbox .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #105c9d;
}
.theme-alternate .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.theme-alternate .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: red;
}
.theme-alternate .mat-chip.mat-standard-chip {
  background-color: #5f5f5f;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip::after {
  background: #ffffff;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: red;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.theme-alternate .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-table {
  background: #393939;
}
.theme-alternate .mat-table thead, .theme-alternate .mat-table tbody, .theme-alternate .mat-table tfoot,
.theme-alternate mat-header-row, .theme-alternate mat-row, .theme-alternate mat-footer-row,
.theme-alternate [mat-header-row], .theme-alternate [mat-row], .theme-alternate [mat-footer-row],
.theme-alternate .mat-table-sticky {
  background: inherit;
}
.theme-alternate mat-row, .theme-alternate mat-header-row, .theme-alternate mat-footer-row,
.theme-alternate th.mat-header-cell, .theme-alternate td.mat-cell, .theme-alternate td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-cell, .theme-alternate .mat-footer-cell {
  color: white;
}
.theme-alternate .mat-calendar-arrow {
  border-top-color: white;
}
.theme-alternate .mat-datepicker-toggle,
.theme-alternate .mat-datepicker-content .mat-calendar-next-button,
.theme-alternate .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.theme-alternate .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-calendar-body-cell-content {
  color: white;
  border-color: transparent;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.theme-alternate .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(16, 92, 157, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: red;
  color: white;
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 0, 0, 0.4);
}
.theme-alternate .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.theme-alternate .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-datepicker-toggle-active {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  color: red;
}
.theme-alternate .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #393939;
  color: white;
}
.theme-alternate .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel {
  background: #393939;
  color: white;
}
.theme-alternate .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .theme-alternate .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #393939;
  }
}
.theme-alternate .mat-expansion-panel-header-title {
  color: white;
}
.theme-alternate .mat-expansion-panel-header-description,
.theme-alternate .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.theme-alternate .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.theme-alternate .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: red;
}
.theme-alternate .mat-focused .mat-form-field-required-marker {
  color: #105c9d;
}
.theme-alternate .mat-form-field-ripple {
  background-color: white;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #105c9d;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: red;
}
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.theme-alternate .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: red;
}
.theme-alternate .mat-error {
  color: red;
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.theme-alternate .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #105c9d;
}
.theme-alternate .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: red;
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.theme-alternate .mat-icon.mat-primary {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-accent {
  color: #105c9d;
}
.theme-alternate .mat-icon.mat-warn {
  color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-input-element:disabled,
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-accent .mat-input-element {
  caret-color: #105c9d;
}
.theme-alternate .mat-warn .mat-input-element,
.theme-alternate .mat-form-field-invalid .mat-input-element {
  caret-color: red;
}
.theme-alternate .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: red;
}
.theme-alternate .mat-list-base .mat-list-item {
  color: white;
}
.theme-alternate .mat-list-base .mat-list-option {
  color: white;
}
.theme-alternate .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-list-item-disabled {
  background-color: #464646;
}
.theme-alternate .mat-list-option:hover, .theme-alternate .mat-list-option:focus,
.theme-alternate .mat-nav-list .mat-list-item:hover,
.theme-alternate .mat-nav-list .mat-list-item:focus,
.theme-alternate .mat-action-list .mat-list-item:hover,
.theme-alternate .mat-action-list .mat-list-item:focus {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-list-single-selected-option, .theme-alternate .mat-list-single-selected-option:hover, .theme-alternate .mat-list-single-selected-option:focus {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-menu-panel {
  background: #393939;
}
.theme-alternate .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-menu-item {
  background: transparent;
  color: white;
}
.theme-alternate .mat-menu-item[disabled], .theme-alternate .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-menu-item .mat-icon-no-color,
.theme-alternate .mat-menu-item-submenu-trigger::after {
  color: white;
}
.theme-alternate .mat-menu-item:hover:not([disabled]),
.theme-alternate .mat-menu-item.cdk-program-focused:not([disabled]),
.theme-alternate .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.theme-alternate .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(243, 243, 243, 0.04);
}
.theme-alternate .mat-paginator {
  background: #393939;
}
.theme-alternate .mat-paginator,
.theme-alternate .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-paginator-decrement,
.theme-alternate .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.theme-alternate .mat-paginator-first,
.theme-alternate .mat-paginator-last {
  border-top: 2px solid white;
}
.theme-alternate .mat-icon-button[disabled] .mat-paginator-decrement,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-increment,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-first,
.theme-alternate .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-progress-bar-background {
  fill: #0097aa;
}
.theme-alternate .mat-progress-bar-buffer {
  background-color: #0097aa;
}
.theme-alternate .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #b7d1e0;
}
.theme-alternate .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #105c9d;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffb3b3;
}
.theme-alternate .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: red;
}
.theme-alternate .mat-progress-spinner circle, .theme-alternate .mat-spinner circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-accent circle, .theme-alternate .mat-spinner.mat-accent circle {
  stroke: #105c9d;
}
.theme-alternate .mat-progress-spinner.mat-warn circle, .theme-alternate .mat-spinner.mat-warn circle {
  stroke: red;
}
.theme-alternate .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-primary .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-accent .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #105c9d;
}
.theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: red;
}
.theme-alternate .mat-radio-button.mat-warn .mat-radio-inner-circle,
.theme-alternate .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .theme-alternate .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .theme-alternate .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: red;
}
.theme-alternate .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-radio-button .mat-ripple-element {
  background-color: #ffffff;
}
.theme-alternate .mat-select-value {
  color: white;
}
.theme-alternate .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-select-panel {
  background: #393939;
}
.theme-alternate .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(243, 243, 243, 0.12);
}
.theme-alternate .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #105c9d;
}
.theme-alternate .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: red;
}
.theme-alternate .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-drawer-container {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-drawer {
  background-color: #393939;
  color: white;
}
.theme-alternate .mat-drawer.mat-drawer-push {
  background-color: #393939;
}
.theme-alternate .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.theme-alternate [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(198, 198, 198, 0.6);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(16, 92, 157, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #105c9d;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: red;
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 0, 0, 0.54);
}
.theme-alternate .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: red;
}
.theme-alternate .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.theme-alternate .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.theme-alternate .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-primary .mat-slider-track-fill,
.theme-alternate .mat-primary .mat-slider-thumb,
.theme-alternate .mat-primary .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-accent .mat-slider-track-fill,
.theme-alternate .mat-accent .mat-slider-thumb,
.theme-alternate .mat-accent .mat-slider-thumb-label {
  background-color: #105c9d;
}
.theme-alternate .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-warn .mat-slider-track-fill,
.theme-alternate .mat-warn .mat-slider-thumb,
.theme-alternate .mat-warn .mat-slider-thumb-label {
  background-color: red;
}
.theme-alternate .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.theme-alternate .mat-slider-focus-ring {
  background-color: rgba(16, 92, 157, 0.2);
}
.theme-alternate .mat-slider:hover .mat-slider-track-background,
.theme-alternate .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled .mat-slider-track-background,
.theme-alternate .mat-slider-disabled .mat-slider-track-fill,
.theme-alternate .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.theme-alternate .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .theme-alternate .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.theme-alternate .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.theme-alternate .mat-step-header.cdk-keyboard-focused, .theme-alternate .mat-step-header.cdk-program-focused, .theme-alternate .mat-step-header:hover {
  background-color: rgba(243, 243, 243, 0.04);
}
@media (hover: none) {
  .theme-alternate .mat-step-header:hover {
    background: none;
  }
}
.theme-alternate .mat-step-header .mat-step-label,
.theme-alternate .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-selected,
.theme-alternate .mat-step-header .mat-step-icon-state-done,
.theme-alternate .mat-step-header .mat-step-icon-state-edit {
  background-color: #105c9d;
  color: white;
}
.theme-alternate .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: red;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.theme-alternate .mat-step-header .mat-step-label.mat-step-label-error {
  color: red;
}
.theme-alternate .mat-stepper-horizontal, .theme-alternate .mat-stepper-vertical {
  background-color: #393939;
}
.theme-alternate .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-horizontal-stepper-header::before,
.theme-alternate .mat-horizontal-stepper-header::after,
.theme-alternate .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate .mat-tab-nav-bar,
.theme-alternate .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group-inverted-header .mat-tab-nav-bar,
.theme-alternate .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.theme-alternate .mat-tab-label, .theme-alternate .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-tab-group[class*=mat-background-] .mat-tab-header,
.theme-alternate .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .theme-alternate .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 151, 170, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-primary .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(183, 209, 224, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #105c9d;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-accent .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 179, 179, 0.3);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: red;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.theme-alternate .mat-tab-group.mat-background-warn .mat-ripple-element, .theme-alternate .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-toolbar {
  background: #393939;
  color: white;
}
.theme-alternate .mat-toolbar.mat-primary {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  background: #105c9d;
  color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  background: red;
  color: white;
}
.theme-alternate .mat-toolbar .mat-form-field-underline,
.theme-alternate .mat-toolbar .mat-form-field-ripple,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.theme-alternate .mat-toolbar .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-focused .mat-form-field-label,
.theme-alternate .mat-toolbar .mat-select-value,
.theme-alternate .mat-toolbar .mat-select-arrow,
.theme-alternate .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.theme-alternate .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.theme-alternate .mat-tooltip {
  background: rgba(95, 95, 95, 0.9);
}
.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-simple-snackbar-action {
  color: inherit;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

edit-transport-dialog h2.mat-dialog-title {
  margin-bottom: 0;
}
edit-transport-dialog .order-no {
  margin-bottom: 10px;
}
edit-transport-dialog mat-dialog-content .status-wrapper {
  margin-bottom: 1em;
}
edit-transport-dialog mat-dialog-content .transport-info {
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1em;
}
edit-transport-dialog mat-dialog-content .transport-info .from {
  margin-bottom: 1em;
}
edit-transport-dialog mat-dialog-content .transport-info .info-row {
  display: flex;
  align-items: center;
}
edit-transport-dialog mat-dialog-content .transport-info .info-row .label-chip {
  background: #555;
  margin-right: 0.5em;
  border-radius: 5px;
  padding: 0.2em;
  color: #fff;
  width: 45px;
  text-align: center;
  font-size: 0.9em;
}
edit-transport-dialog mat-dialog-content .transport-info .info-row .name-address .name {
  line-height: normal;
}
edit-transport-dialog mat-dialog-content .transport-info .info-row .name-address .address {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.54);
  line-height: normal;
}
edit-transport-dialog mat-dialog-content .transport-info .info-row .date {
  flex: 1 0 auto;
  text-align: right;
}
edit-transport-dialog mat-dialog-content .denthub-orders-wrapper {
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 1em;
}
edit-transport-dialog mat-dialog-content .denthub-orders-wrapper h5 {
  font-size: 1em;
}
edit-transport-dialog mat-dialog-content .denthub-orders-wrapper li {
  list-style: square;
  margin-left: 18px;
}
edit-transport-dialog mat-dialog-content .denthub-orders-wrapper li a {
  font-size: 0.9em;
}
edit-transport-dialog mat-dialog-content .transport-details-wrapper {
  flex-wrap: wrap;
}
edit-transport-dialog mat-dialog-content .transport-details-wrapper .no-of-packages-wrapper {
  flex: 1 0 100%;
  padding: 0;
  margin-bottom: 1em;
}
edit-transport-dialog mat-dialog-content .transport-details-wrapper .comments-wrapper {
  flex: 1 0 100%;
  padding: 0;
}
edit-transport-dialog mat-dialog-content .deadline-wrapper {
  margin-top: 1em;
}
edit-transport-dialog mat-dialog-content .full-width-field {
  width: 100%;
}
edit-transport-dialog .close {
  float: right;
  top: -20px;
  right: -20px;
}

.btn-float-right {
  float: right;
}

.text-area-width {
  width: auto !important;
}

.description-truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#limited-dentist-form-wrapper {
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 30px;
  background-color: #f3f3f3;
}
#limited-dentist-form-wrapper .mat-horizontal-content-container {
  padding: 0;
}
#limited-dentist-form-wrapper .limited-dentist-card {
  width: 100%;
  max-width: 850px;
}
#limited-dentist-form-wrapper .limited-dentist-card .header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#limited-dentist-form-wrapper .limited-dentist-card .header-wrapper .mat-card-header-text {
  margin: 0;
}
#limited-dentist-form-wrapper .limited-dentist-card .header-wrapper .logo {
  max-height: 75px;
}
#limited-dentist-form-wrapper .limited-dentist-card .section-description {
  margin-bottom: 1em;
}
#limited-dentist-form-wrapper .limited-dentist-card .grid-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  -moz-column-gap: 1em;
       column-gap: 1em;
  margin-bottom: 1em;
}
#limited-dentist-form-wrapper .limited-dentist-card .grid-wrapper .field-wrapper mat-form-field {
  width: 100%;
}
#limited-dentist-form-wrapper .limited-dentist-card .grid-wrapper .field-wrapper mat-form-field.mat-form-field-invalid {
  margin-bottom: 1em;
}
#limited-dentist-form-wrapper .limited-dentist-card .actions {
  display: flex;
  justify-content: flex-end;
}
#limited-dentist-form-wrapper .limited-dentist-card .login-link-wrapper {
  text-align: center;
  font-size: 0.5em;
}
#limited-dentist-form-wrapper .powered-denthub {
  margin-top: 0.5em;
}
#limited-dentist-form-wrapper .powered-denthub a {
  font-weight: normal;
  font-size: 10px;
  color: #ccc;
}
#limited-dentist-form-wrapper .error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

body.framed-limited-dentist-form {
  background: none !important;
}
body.framed-limited-dentist-form #limited-dentist-form-wrapper {
  background: none;
  padding: 0;
  justify-content: start;
}
body.framed-limited-dentist-form #limited-dentist-form-wrapper mat-card .header-wrapper .logo {
  display: none;
}

@media only screen and (max-width: 660px) {
  body.framed-limited-dentist-form #limited-dentist-form-wrapper .limited-dentist-card .grid-wrapper {
    grid-template-columns: auto auto;
  }

  body.limited-dentist-form {
    background: none !important;
  }
}
@media only screen and (max-width: 520px) {
  body.framed-limited-dentist-form #limited-dentist-form-wrapper .limited-dentist-card .grid-wrapper {
    grid-template-columns: auto;
    -moz-column-gap: 0.5em;
         column-gap: 0.5em;
    margin-bottom: 0.5em;
  }
}
#dentist-signup-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
#dentist-signup-form-wrapper .mat-stepper-horizontal {
  padding: 1em;
  background: none;
}
#dentist-signup-form-wrapper .mat-horizontal-content-container {
  padding: 0;
}
#dentist-signup-form-wrapper .section-description {
  margin-bottom: 1em;
}
#dentist-signup-form-wrapper .grid-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  -moz-column-gap: 1em;
       column-gap: 1em;
  margin-bottom: 1em;
}
#dentist-signup-form-wrapper .grid-wrapper .field-wrapper mat-form-field {
  width: 100%;
}
#dentist-signup-form-wrapper .grid-wrapper .field-wrapper mat-form-field.mat-form-field-invalid {
  margin-bottom: 1em;
}
#dentist-signup-form-wrapper .actions {
  display: flex;
  justify-content: flex-end;
}
#dentist-signup-form-wrapper .error-message {
  color: red;
  font-weight: bold;
  text-align: center;
}

body.framed-dentist-signup-form {
  background: none !important;
}

@media only screen and (max-width: 680px) {
  #dentist-signup-form-wrapper {
    display: block;
  }
  #dentist-signup-form-wrapper .grid-wrapper {
    grid-template-columns: auto;
    -moz-column-gap: 0.5em;
         column-gap: 0.5em;
    margin-bottom: 0.5em;
  }
}
#invoice-form-wrapper .invoice-type-wrapper {
  margin-bottom: 1em;
}
#invoice-form-wrapper .invoice-type-wrapper .invoice-type-label {
  font-weight: bold;
  font-size: 1.2em;
}
#invoice-form-wrapper .invoice-type-wrapper .mat-radio-button ~ .mat-radio-button {
  margin-left: 16px;
}

#denthub-header {
  padding-right: 20px;
}
#denthub-header #Notifications .manage-all-notification {
  right: -20px;
}
#denthub-header #Notifications.open button:after {
  right: 11px;
  left: -4px;
}

.main-layout {
  width: calc(100% - 15px);
  padding: 0 0 0 70px;
}
.main-layout .container {
  width: 100%;
  margin-top: 0 !important;
}
.main-layout #menu-profile.dropdown.sidebar-menu {
  margin-left: 0;
  z-index: 112;
}
.main-layout #menu-profile.dropdown.sidebar-menu button {
  background: none !important;
  border: 0;
  color: #fff;
  font-size: 30px;
  position: fixed;
  top: 6px;
  left: 4px;
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu {
  position: fixed;
  display: block;
  margin-top: 0;
  top: 48px;
  left: 0;
  border: 0;
  border-radius: 0;
  width: 210px;
  min-width: inherit !important;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
  background-color: #fafafa;
  padding-top: 10px;
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a span,
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a mat-icon {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 25px;
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a span svg,
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a mat-icon svg {
  width: 24px;
  height: 24px;
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li a:hover {
  color: rgba(0, 0, 0, 0.87);
}
.main-layout #menu-profile.dropdown.sidebar-menu ul.dropdown-menu li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.main-layout #menu-profile.dropdown.sidebar-menu.open {
  width: 60px;
}
.main-layout #menu-profile.dropdown.sidebar-menu.open ul.dropdown-menu {
  width: 60px;
}
.main-layout #menu-profile.dropdown.sidebar-menu.open ul.dropdown-menu li a {
  font-size: 0;
}
.main-layout .manage-right-panel {
  width: 100%;
  margin-top: -20px;
}
.main-layout .manage-right-panel .sub-nav-bar {
  position: fixed;
  width: calc(100% - 50px);
  left: 50px;
  top: 64px;
  z-index: 98;
}
.main-layout .manage-right-panel .manage-common-content {
  margin-top: 0;
}
.main-layout .manage-right-panel #navBar {
  padding-left: 15px;
}
.main-layout .manage-right-panel #reports {
  margin-top: 150px;
}
.main-layout .manage-right-panel #construction-section.simple-order-section {
  margin-top: 60px;
}
.main-layout .manage-right-panel #manage-actions .container,
.main-layout .manage-right-panel #manage-labs .container {
  margin-top: 180px !important;
}
.main-layout.menuOpened {
  padding-left: 240px;
}

@media (max-width: 780px) {
  .main-layout #menu-profile.dropdown.sidebar-menu button {
    display: none;
  }
}
@media (max-width: 1024px) {
  .main-layout {
    padding: 0 0 0 0;
  }

  .navbar-nav {
    margin-left: 50px;
  }
}
@media (min-width: 1024px) and (max-width: 1365px) {
  .main-layout #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(2) {
    width: 13%;
  }
}
@media (min-width: 1366px) and (max-width: 1599px) {
  .main-layout #manage-actions .manage-actions .categories-section .action-data-table .table-head-title label:nth-child(2) {
    width: 18%;
  }
  .main-layout .details-page-label-black label {
    margin-right: 20px;
  }
  .main-layout #construction-section .Constructions {
    padding: 18px 10px 50px;
  }
}
.manage-all-notification .dropdown-menu::-webkit-scrollbar {
  width: 5px;
}
.manage-all-notification .dropdown-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.manage-all-notification .dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #105c9d;
}

#navBar.menuOpened {
  padding-left: 255px;
}

.current-user-card {
  padding-top: 10px !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;
}
.current-user-card .avatar {
  background-size: cover;
}
.current-user-card .mat-card-title {
  font-size: 15px;
  margin-bottom: 5px !important;
}
.current-user-card .mat-card-subtitle {
  font-size: 13px;
}

#order-details-subnav {
  margin-right: 20px;
}
#order-details-subnav .tab-nav {
  margin-bottom: 20px;
}
#order-details-subnav .tab-nav .mat-tab-header {
  border: none;
}
#order-details-subnav .tab-nav .mat-tab-label {
  min-width: auto;
}
#order-details-subnav .tab-nav .mat-tab-label .mat-tab-label-content {
  text-transform: uppercase;
  font-size: 14px;
}
#order-details-subnav .tab-nav .mat-tab-body-wrapper {
  display: none;
}
#order-details-subnav .tab-hr {
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
}
#order-details-subnav .action-bar {
  display: flex;
}
#order-details-subnav .action-bar .status-area {
  flex: 1 0 25%;
}
#order-details-subnav .action-bar .status-area button {
  float: left;
  margin-bottom: 10px;
}
#order-details-subnav .action-bar .status-area .info-area {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #e5f6fd;
  display: flex;
  padding: 6px 16px;
  color: #014361;
  float: left;
  align-items: center;
}
#order-details-subnav .action-bar .status-area .info-area .mat-icon {
  margin-left: 5px;
}
#order-details-subnav .action-bar .action-area {
  text-align: right;
  flex: 1 0 75%;
}
#order-details-subnav .action-bar .button-icon {
  margin-left: -5px;
}
#order-details-subnav .action-bar .mat-button-wrapper {
  text-transform: uppercase;
}